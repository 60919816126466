// Entry point for the build script in your package.json

import imagesLoaded from "imagesloaded";
import Isotope from "isotope-layout";

function readyHandler(fn: () => void) {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

readyHandler(() => {
  const container = document.querySelector(".image-grid");

  imagesLoaded(container, () => {
    new Isotope(container as HTMLElement, {
      itemSelector: ".image",
      masonry: {
        columnWidth: 226 + 14
      }
    });
  });
});
